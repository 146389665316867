<template>
  <BaseModal
    name="modal-address"
    size="lg"
    :title="$t('seller.address.listagem_enderecos.my_addresses')"
    @hidden="close"
    @shown="openModal"
  >
    <div class="d-flex justify-content-center" v-if="loading">
      <b-spinner label="Loading..."></b-spinner>
    </div>

    <div class="lista-enderecos" v-if="!loading && !newAddress">
      <div v-if="enderecos.length">
        <span class="principal">
          <img src="@/assets/img/icons/mini-check.svg" />
          {{ $t('seller.address.listagem_enderecos.principal_address') }}
        </span>
        <div
          v-for="endereco in enderecos"
          :key="endereco.id"
          class="item-endereco"
          data-anima="bottom"
        >
          <AddressItem
            :endereco="endereco"
            :principal="endereco.main == '1' ? true : false"
            :actions="true"
            @update="getAddress(true)"
          />
        </div>
      </div>
      <b-row
        v-if="!loading && !enderecos.length"
        class="justify-content-center"
      >
        <p class="nao-encontrado">{{ $t('seller.address.listagem_enderecos.no_address_found') }}</p>
      </b-row>
    </div>

    <div v-if="!loading && newAddress" class="novo-endereco">
      <a href="#" @click.prevent="newAddress = false" class="voltar">{{ $t('seller.address.listagem_enderecos.back') }}</a>
      <h4>{{ $t('seller.address.listagem_enderecos.new_address') }}</h4>
      <b-form data-vv-scope="address">
        <b-row>
          <b-col cols="12" lg="4" md="12">
            <b-form-group :label="$t('seller.address.listagem_enderecos.postal_code')" label-for="zip_code">
              <b-form-input
                data-test="zip_code"
                id="zip_code"
                v-model="data.zip_code"
                :placeholder="$t('seller.address.listagem_enderecos.postal_code')"
                type="text"
                name="zip_code"
                v-mask="['#####-###']"
                v-validate="'required'"
                @keyup="getCEP"
              ></b-form-input>
              <b-form-invalid-feedback
                :state="submit ? !errors.has('address.zip_code') : null"
              >
                {{ $t('seller.address.listagem_enderecos.postal_code') }} Preencha seu CEP.
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>

          <b-col cols="12" lg="5" md="12">
            <b-form-group :label="$t('seller.address.listagem_enderecos.public_place')" label-for="street">
              <b-form-input
                data-test="street"
                id="street"
                v-model="data.street"
                :placeholder="$t('seller.address.listagem_enderecos.type_public_place')"
                type="text"
                name="street"
                v-validate="'required'"
              ></b-form-input>
              <b-form-invalid-feedback
                :state="submit ? !errors.has('address.street') : null"
              >
                {{ $t('seller.address.listagem_enderecos.fill_public_place') }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>

          <b-col cols="12" lg="3" md="12">
            <b-form-group :label="$t('seller.address.listagem_enderecos.number')" label-for="number">
              <b-form-input
                data-test="number"
                ref="numberAddress"
                v-model="data.number"
                :placeholder="$t('seller.address.listagem_enderecos.number')"
                type="text"
                name="number"
                v-validate="'required'"
              ></b-form-input>
              <b-form-invalid-feedback
                :state="submit ? !errors.has('address.number') : null"
              >
                {{ $t('seller.address.listagem_enderecos.fill_number') }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12" md="6">
            <b-form-group :label="$t('seller.address.listagem_enderecos.city')" label-for="city">
              <b-form-input
                data-test="city"
                id="city"
                v-model="data.city"
                :placeholder="$t('seller.address.listagem_enderecos.city')"
                type="text"
                name="city"
                v-validate="'required'"
              ></b-form-input>
              <b-form-invalid-feedback
                :state="submit ? !errors.has('address.city') : null"
              >
                {{ $t('seller.address.listagem_enderecos.fill_city') }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>

          <b-col cols="12" md="6">
            <b-form-group :label="$t('seller.address.listagem_enderecos.neighborhood')" label-for="neighborhood">
              <b-form-input
                data-test="neighborhood"
                id="neighborhood"
                v-model="data.neighborhood"
                :placeholder="$t('seller.address.listagem_enderecos.neighborhood')"
                type="text"
                name="neighborhood"
                v-validate="'required'"
              ></b-form-input>
              <b-form-invalid-feedback
                :state="submit ? !errors.has('address.neighborhood') : null"
              >
                {{ $t('seller.address.listagem_enderecos.fill_neighborhood') }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12" lg="7" md="12">
            <b-form-group :label="$t('seller.address.listagem_enderecos.complement')" label-for="complement">
              <!-- complemento required -->
              <b-form-input
                data-test="complement"
                id="complement"
                v-model="data.complement"
                :placeholder="$t('seller.address.listagem_enderecos.complement_of_address')"
                type="text"
                name="complement"
                v-validate="'required'"
              ></b-form-input>
              <b-form-invalid-feedback
                :state="submit ? !errors.has('address.complement') : null"
              >
                {{ $t('seller.address.listagem_enderecos.fill_complement') }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>

          <b-col cols="12" lg="5" md="12">
            <b-form-group :label="$t('seller.address.listagem_enderecos.state')" label-for="state">
              <b-form-input
                data-test="state"
                id="state"
                v-model="data.state"
                :placeholder="$t('seller.address.listagem_enderecos.state')"
                type="text"
                name="state"
                v-validate="'required'"
              ></b-form-input>
              <b-form-invalid-feedback
                :state="submit ? !errors.has('address.state') : null"
              >
                {{ $t('seller.address.listagem_enderecos.fill_state') }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12" md="6">
            <b-form-group label="" label-for="is_main ">
              <b-form-checkbox
                v-model="data.main"
                name="is_main  "
                size="lg"
                switch
              >
                <p class="info-checkbox">
                  {{ $t('seller.address.listagem_enderecos.set_as_default_address') }}
                  <span class="descricao-checkbox"
                    >
                      {{ $t('seller.address.listagem_enderecos.address_will_be_updated_as_the_main_one_in_your_account') }}
                    </span
                  >
                </p>
              </b-form-checkbox>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="6" v-if="user && user.company">
            <b-form-group :label="$t('seller.address.listagem_enderecos.state_registration')" label-for="state_register">
              <b-form-input
                data-test="state_register"
                id="state_register"
                v-model="data.state_register"
                :placeholder="$t('seller.address.listagem_enderecos.state_registration_of_your_company')"
                type="number"
                name="state_register"
                v-validate="'required|numeric'"
              ></b-form-input>
              <b-form-invalid-feedback
                :state="submit ? !errors.has('address.state_register') : null"
              >
                {{ $t('seller.address.listagem_enderecos.fill_the_state_registration') }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </div>
    <div class="text-right mt-3">
      <BaseButton
        variant="secondary_outline"
        class="addEndereco"
        :disabled="loading"
        @click="addAddress"
        >{{
          newAddress ? $t('seller.address.listagem_enderecos.save_address') : $t('seller.address.listagem_enderecos.add_address')
        }}</BaseButton
      >
    </div>

    <template v-slot:footer="{}">
      <div></div>
    </template>
  </BaseModal>
</template>

<script>
import AddressService from "@/services/resources/AddressService";
const serviceAddress = AddressService.build();

import AddressItem from "./Item";
import axios from "axios";

export default {
  name: "ModalAddress",
  data() {
    return {
      loading: false,
      submit: false,
      user: {},
      enderecos: [],
      newAddress: false,
      data: {
        main: false,
        zip_code: null,
        number: null,
        street: null,
        neighborhood: null,
        city: null,
        state: null,
        complement: null,
      },
    };
  },
  props: {
    enderecosResponse: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    AddressItem,
  },
  methods: {
    openModal() {
      this.getAddress();
      this.getUser();
    },
    getUser() {
      this.$store.dispatch("userRequest").then((response) => {
        this.user = response;
      });
    },
    close() {
      this.reset();
      this.$emit("close");
    },
    reset() {
      this.loading = false;
      this.submit = false;
      this.enderecos = [];
      this.newAddress = false;
      this.data = {
        main: false,
        zip_code: null,
        number: null,
        street: null,
        neighborhood: null,
        complement: null,
        city: null,
        state: null,
      };
    },
    addAddress() {
      if (!this.newAddress) {
        this.newAddress = true;
      } else {
        this.submit = true;
        this.$validator.validateAll("address").then(async (result) => {
          if (result) {
            this.loading = true;
            serviceAddress
              .create(this.data)
              .then(() => {
                this.reset();
                this.getAddress(true);
                this.$bvToast.toast(this.$t('seller.address.listagem_enderecos.addresses_added'), {
                  title: this.$t('seller.address.listagem_enderecos.addresses'),
                  variant: "success",
                  autoHideDelay: 5000,
                  appendToast: true,
                });
              })
              .finally(() => {
                this.loading = false;
              });
          }
        });
      }
    },
    getCEP() {
      if (
        this.data.zip_code &&
        this.data.zip_code.replace("-", "").length >= 8
      ) {
        this.loading = true;

        axios
          .get(
            "https://viacep.com.br/ws/" +
              this.data.zip_code.replace("-", "") +
              "/json"
          )
          .then((response) => {
            this.data.street = response.data.logradouro;
            this.data.neighborhood = response.data.bairro;
            this.data.city = response.data.localidade;
            this.data.state = response.data.uf;
            setTimeout(() => {
              this.$refs.numberAddress.focus();
            });
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    getAddress(force = false) {
      this.enderecos = [];
      if (!this.enderecosResponse.length || force) {
        this.loading = true;
        serviceAddress
          .search()
          .then((response) => {
            this.enderecos = response;
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.enderecos = this.enderecosResponse;
      }
    },
  },
};
</script>

<style scoped>
.item-endereco + .item-endereco {
  margin-top: 10px;
}
.addEndereco {
  padding: 0 20px !important;
  height: 40px !important;
  font-size: 12px;
  text-align: right;
}
.novo-endereco h4 {
  font-size: 16px;
  font-weight: 600;
  color: #2a63ab;
  margin-bottom: 20px;
}
.novo-endereco .voltar {
  font-size: 14px;
  display: inline-block;
  margin-bottom: 10px;
  color: #81858e;
}
.principal {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 13px;
  color: #81858e;
  margin-bottom: 15px;
}
</style>
